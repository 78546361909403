<template>
  <div>
    <v-row class="pt-10 mt-1 mx-5 mb-10" v-if="allPosts.length > 0">
      <v-col
        v-for="post in allPosts"
        :key="post.id"
        cols="12"
        sm="4"
        md="3"
        lg="3"
        xl="3"
      >
        <PostThumbnail class="thumbnail" :post="post" />
      </v-col>
    </v-row>
    <v-row class="pa-12" v-else>
      <v-col>
        No Posts available
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PostThumbnail from '../../post/PostThumbnail.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'KepasWorshipIndex',
  data() {
    return {
      posts: '',
    };
  },
  components: { PostThumbnail },
  computed: mapGetters(['allPosts']),

  methods: {
    ...mapActions(['getPosts']),
  },
  created() {
    this.getPosts('크레파스 워십');
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  cursor: pointer;
}
</style>
